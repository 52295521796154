@import 'constants.scss';

.container {
    display: flex;
    padding: 10px 3rem;
    font-size: 1.2rem;
    align-items: center;
    font-family: "Poppins", "sans-serif";
    text-transform: capitalize;
    gap: 2rem;
    position: fixed;
    top: 0;
    background: white;
    border-bottom: 1px solid #eeeeee;
    z-index: 3;
    width: 100vw;
    .logo {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 1.7rem;

        > :first-child {
            width: 2rem;
            height: 2rem;
        }

        > :nth-child(2) {
            color: $primary;
        }
    }

    .menu {
        font-family: "Poppins";
        font-weight: 500;
        display: flex;
        list-style: none;
        gap: 1rem;
        margin-bottom: 10px;
        font-size: 1.025rem;

        li {
            position: relative;
            a {
                color: inherit;
                text-decoration: none;
            }
            .active{
                color: $primary;
            }
            .active::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 5px;
                background-color: $primary;
                bottom: -1.3rem;
            }
        }
    }

    .profileContainer {
        margin-left: auto;
        font-weight: 500;
        display: flex;
        gap: 0.6rem;
        align-items: center;
        cursor: pointer;
    }
}