.container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: var(--black);

    .tagWrapper{
      display: flex;
      flex-direction: column;
      gap: .5rem;
      width: 100%;
      align-items: center;
      margin-bottom: -1rem;
      
      .tagContainer{
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: center;
    }

    .tagMessage{
      color: red;
      font-size: 0.9rem;
      transform: translateX(42%);
    }
  }
  
    .rating {
      span {
        font-size: 1.2rem;
        font-weight: 500;
      }
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
    }
  
    .heading {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      > :nth-child(1) {
        font-size: 1.5rem;
        font-weight: bold;
      }
      > :nth-child(2) {
        font-size: 0.9rem;
      }
    }
  }
  