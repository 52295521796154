@import "constants.scss";
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 65rem;
    gap: 1rem;
    .row {
        display: flex;
        gap: 1rem;

        >div {
            flex: 1;
        }
    }

    .switch {
        display: flex;
        gap: 1rem;
        font-weight: 500;

        :global(.mantine-Switch-track) {
            background-color: $primary;
        }

        :global(.mantine-Switch-thumb) {
            border-color: $primary;
        }

        :global(.mantine-Switch-track) {
            border-color: $primary;
        }
    }

}