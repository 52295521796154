.wrapper {
    .heading {
      font-size: 1.2rem;
      font-weight: 500;
    }
    .container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      height: 100%;
      max-height: 40rem;
      padding: 2rem 0rem;
      overflow: auto;
      .attendeeDetail {
        display: flex;
        gap: 0.5rem;
        .avatar {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
  