@import 'constants.scss';

.tabsHeader {
    border: none;
    box-shadow: 0px 10px 8px -6px rgba(0, 0, 0, 0.1);
    max-width: 65rem;
  }

  .tab {
    font-size: 1rem;
    font-weight: 500;
    width: 10rem;

    &[data-active] {
      border-bottom: 5px solid $primary;
    }
  }

  .tabDetail{
    margin-top: 2rem;
    overflow: auto;
    height:74vh;
  }