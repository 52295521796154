@import 'constants.scss';
.container {
    >:nth-child(1)
    {
        color: $black;
        display: block;
        font-size: .8rem;
        font-weight: 500;
        margin-bottom: .5rem;
    }
    :global(.react-tagsinput-tag) {
        background: rgb(234, 235, 236);;
        border-radius: 99px;
        border: none;
        color: black;
        padding: 6px 14px;
    }
    :global(.react-tagsinput-input)
    {
        color: $black;
        width: fit-content;

    }
}