// here define SCSS variables that will be accessible through
// all over the app


$primary: #39a8f0;
$black: #373b54;
$grey: rgb(62 65 87 / 12%);
$offwhite: #f5f5f5;
$greyText: rgba(62, 65, 87, 0.53);
$header-height: 5.2rem;
$cardShadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
$golden: #fab005