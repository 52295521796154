.container {
    padding: 2rem;

    .head {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .title {
            margin: auto;
            align-self: center;
            font-weight: 500;
            font-size: 1.2rem;
        }

        .des {
            font-size: 0.9rem;
            color: rgb(94, 94, 94);
        }
    }

    .body {
        margin-top: 2rem;

        .listContainer {
            margin-top: 2rem;
            width: 100%;
            .title{
                font-size: 1rem;
                font-weight: 500;
            }
            .list {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                height: 35rem;
                margin-top: 2rem;
                padding-bottom: 4rem;
                margin-bottom: 4rem;
                padding-right: 1.5rem;
                .person {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                    .detail {
                        display: flex;
                        gap: 1rem;

                        .name {
                            display: flex;
                            flex-direction: column;

                            >:nth-child(2) {
                                font-size: 0.9rem;
                                color: grey;
                            }
                        }
                    }

                    .remove {
                        cursor: pointer;
                    }
                }
            }

            .filter{
                margin-top: 1rem;
            }
        }

    }
}