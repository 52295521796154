.container{
    overflow: visible;
    >div:not(:last-child)::after{
        content: "";
        height: 1px;
        width: 90%;
        background: #00000026;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-top: 19px;
    }
}