@import "constants.scss";
.modal {
    :global(.mantine-Modal-modal) {
        overflow: hidden;
    }

    :global(.mantine-Modal-modal) {
        width: 85vw;
        max-width: 100rem;
    }

    .container {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 1.5rem;
        >div {
            flex: 1;
        }

        .tiltGradientContainer {
            width: 140%;
            height: 15rem;
            top: -9rem;
            left: -5rem;
            position: absolute;
            background: linear-gradient(87.3deg, #2BDFF8 17.44%, rgba(97, 54, 222, 0.48) 98.58%);
            transform: rotate(-4.46deg);
            z-index: -1;
        }


        .right {
            >img {
                width: 100%;
            }
        }


        .left {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 2rem;
            .logo {
                width: 7rem;
            }

            .typography {
                >span{
                    display: block;
                    color: $black;
                }
                >:nth-child(1) {
                    font-size: 1.7rem;
                    font-weight: 500; 
                    margin-bottom: 1rem;
                }
            }
        }
    }
}