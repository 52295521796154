@import 'constants.scss';

.rating{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 2px solid $golden;
    padding: 5px;
    border-radius: 99px;
    gap: 10px;
    width: 20rem;
    cursor: pointer;
}