.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex: 1!important;
    height: 15rem;
   .chart
   {
    width: 100%;
    margin: -6.6rem 0;
    z-index: -1;
   }
   .detail{
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 1rem;
    >:nth-child(1)
    {
        display: flex;
        align-items: flex-end;
        gap: 1rem;
    }
    >:nth-child(2)
    {
        display: flex;
        gap: 1rem;
        align-items: flex-end;
       
    }
   }

}