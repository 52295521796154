body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --golden: #fab005;
  --blue: #39a8f0;
}

#gaintime-app,
#invitationPage {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  color: #373b54;
}

.blue-gradient {
  position: absolute;
  width: 200px;
  height: 200px;
  background: #7aebfb;
  filter: blur(90px);
}
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.insights-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 65rem;
  gap: 1rem;
}
.card {
  border: 1px solid #cecece;
  border-radius: 10px;
  padding: 15px;
  transition: all 300ms ease-in;
  display: flex;
  min-height: 13rem;
}
.card:hover {
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2);
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(196, 197, 198, 0.451) ;
  border-radius: 5px;
  width: 5px;
}
.mantine-Modal-title{
  font-weight: bold;
  font-size: 1.2rem;
}