.attend {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: grey;
    width: max-content;
    gap: 1.5rem;

    &.workspace {
        @media (max-width: 1300px) {
            flex-direction: column;
            align-items: flex-end;
            gap: .5rem;
            bottom: -4rem;
        }
    }

    .chips {
        display: flex;
        gap: 0.5rem;

        :global(.mantine-Chip-iconWrapper) {
            display: none;
        }

        :global(.mantine-Chip-label) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}